<template>
  <el-card class="box-card" shadow="always">
     <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="title"
      @head-save="submitForm()"
      @head-back="showCard()"
       @head-ltx="handleCheckConnection()"
    ></head-layout>
    <!-- <div slot="header" class="clearfix">

      <el-button-group style="float: right;">
        <el-button v-hasPerm="['metadata:datasource:add']"  size="mini" icon="el-icon-plus" round :loading="loadingOptions.loading" :disabled="loadingOptions.isDisabled" @click="submitForm">{{ loadingOptions.loadingText }}</el-button>
        <el-button size="mini" icon="el-icon-back" round @click="showCard">{{$t('cip.cmn.btn.goBackBtn')}}</el-button>
      </el-button-group>
    </div> -->
    <div class="body-wrapper">
      <!-- <el-steps :active="active" finish-status="success" align-center>
        <el-step :title="$t('cip.dc.datasource.field.sjyxx')" />
        <el-step :title="$t('cip.dc.datasource.field.ljxx')" />
      </el-steps> -->


       <el-collapse v-model="activeName" style="margintop: 10px">
      <el-collapse-item name="1">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t('cip.dc.datasource.field.sjyxx') }}
          </i>
        </template>


         <el-form  ref="form" :model="form" :rules="rules" label-width="100px">
          <el-row>
             <el-col :span="8">
 <el-form-item :label="$t('cip.dc.datasource.field.status')" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio

              v-for="dict in statusOptions"
              :key="dict.id"
              :label="dict.itemText"
            >{{ dict.itemValue }}</el-radio>
          </el-radio-group>
        </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('cip.dc.datasource.field.datasourceType')" prop="dbType">
          <el-select v-model="form.dbType">
            <el-option
              v-for="item in dbTypeOptions"
              :key="item.id"
              :label="item.itemValue"
              :value="item.itemText"
            />
          </el-select>
        </el-form-item>
            </el-col>
            <el-col :span="8">
               <el-form-item :label="$t('cip.dc.datasource.field.name')" prop="sourceName">
          <el-input v-model="form.sourceName" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.name')" />
        </el-form-item>
            </el-col>

          </el-row>


          <el-row>
            <el-col :span="8">

                <el-form-item :label="$t('cip.dc.offlineDComission.modelState')">
          <el-radio-group v-model="form.modelState">
            <el-radio :label="1">{{$t('cip.dc.offlineDComission.is')  }} &nbsp;&nbsp;</el-radio>
    <el-radio :label="0">{{$t('cip.dc.offlineDComission.no')  }}</el-radio>
          </el-radio-group>


        </el-form-item>
            </el-col>
             <!-- <el-col :span="16">
  <el-form-item :label="$t('cip.dc.datasource.field.remark')" prop="remark">
          <el-input v-model="form.remark" type="textarea" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.remark')" />
        </el-form-item>

             </el-col> -->
          </el-row>


          <el-row>
<el-col :span="24">
  <el-form-item :label="$t('cip.dc.datasource.field.remark')" prop="remark">
          <el-input v-model="form.remark" type="textarea" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.remark')" />
        </el-form-item>

             </el-col>
          </el-row>





      </el-form>

      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          <i
            class="avue-group__title avue-group__header"
            style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "
            ><i
              class="el-icon-document"
              style="font-size: 20px; padding: 0 10px"
            ></i>
            {{ $t('cip.dc.datasource.field.ljxx') }}
          </i>
        </template>


          <el-form  ref="form2" :model="form2" :rules="rules2" label-width="80px">

            <el-row>
              <el-col :span="8">
                 <el-form-item :label="$t('cip.dc.datasource.field.host')" prop="host">
          <el-input v-model="form2.host" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.host')" />
        </el-form-item>
              </el-col>
              <el-col :span="8">
                 <el-form-item :label="$t('cip.dc.datasource.field.port')" prop="port">
          <el-input v-model="form2.port" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.port')" />
        </el-form-item>
              </el-col>
              <el-col :span="8">
                  <el-form-item v-if="form.dbType === '3' || form.dbType === '4'" :label="$t('cip.dc.datasource.field.sid')" prop="sid">
          <el-input v-model="form2.sid" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.sid')" />
        </el-form-item>

        <el-form-item v-if="form.dbType !== '3' && form.dbType !== '4'" :label="$t('cip.dc.datasource.field.dbName')" prop="dbName">
          <el-input v-model="form2.dbName" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.dbName')" />
        </el-form-item>
              </el-col>
            </el-row>
             <el-row>
              <el-col :span="8">
   <el-form-item :label="$t('cip.dc.datasource.field.username')" prop="username">
          <el-input v-model="form2.username" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.username')" />
        </el-form-item>
              </el-col>

               <el-col :span="8">

  <el-form-item :label="$t('cip.dc.datasource.field.password')" prop="password">
          <el-input v-model="form2.password" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datasource.field.password')" />
        </el-form-item>
               </el-col>
               <!-- <el-col :span="8">
  <el-form-item>
          <el-button v-hasPerm="['metadata:datasource:connect']" size="mini" type="primary" @click="handleCheckConnection">{{$t("cip.dc.datasource.field.ltxcs")}}</el-button>
        </el-form-item>
               </el-col> -->

            </el-row>







      </el-form>

      </el-collapse-item>








    </el-collapse>




      <!-- <el-button v-if="active == 1" style="margin-top: 12px;" @click="handleNextStep">{{$t('datacenter.btn.next')}}</el-button>
      <el-button v-if="active == 2" style="margin-top: 12px;" @click="handleLastStep">{{$t('datacenter.btn.prev')}}</el-button> -->
    </div>
  </el-card>
</template>

<script>
import { addDataSource, checkConnection } from '@/api/metadata/datasource'
import HeadLayout from "@/views/components/layout/head-layout";

import { getDicts } from '@/api/dict'
export default {
   components: {
    HeadLayout,
  },
  name: 'DataSourceAdd',
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      activeName:["1","2"],
       headBtnOptions: [
        {
          label: this.$t("cip.dc.datasource.field.ltxcs"),
          emit: "head-ltx",
          type: "button",
          icon: "",
          loading: false,
        },
        {
          label: this.$t("cip.dc.OffCollection.field.Startsynchronization"),
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
          loading: false,
        },
         {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-back",
          type: "button",
          icon: "",
          loading: false,
        },
      ],
      title: this.$t('cip.dc.datasource.field.sjyxz'),
      // 展示切换
      showOptions: {
        data: {},
        showList: true,
        showAdd: false,
        showEdit: false,
        showDetail: false
      },
      // 保存按钮
      loadingOptions: {
        loading: false,
        loadingText: this.$t('cip.cmn.btn.saveBtn'),
        isDisabled: false
      },
      active: 1,
      // 表单参数
      form: {
        id: undefined,
        dbType: undefined,
        themeId: undefined,
        sourceName: undefined,
        dbSchema: {},
        status: '1',
        modelState:0,
        remark: undefined
      },
      // 表单校验
      rules: {
        dbType: [
          { required: true, message: this.$t('cip.dc.datasource.field.datasourceType')+this.$t('datacenter.form.notEmpty'), trigger: 'change' }
        ],
        sourceName: [
          { required: true, message: this.$t('cip.dc.datasource.field.name')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ]
      },
      form2: {
        host: undefined,
        port: undefined,
        dbName: undefined,
        username: undefined,
        password: undefined,
        sid: undefined
      },
      rules2: {
        host: [
          { required: true, message: this.$t('cip.dc.datasource.field.host')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        port: [
          { required: true, message:  this.$t('cip.dc.datasource.field.port')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        sid: [
          { required: true, message:  this.$t('cip.dc.datasource.field.sid')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        dbName: [
          { required: true, message:  this.$t('cip.dc.datasource.field.dbName')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        username: [
          { required: true, message:  this.$t('cip.dc.datasource.field.username')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        password: [
          { required: true, message:  this.$t('cip.dc.datasource.field.password')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ]
      },
      // 状态数据字典
      statusOptions: [],
      // 数据源类型数据字典
      dbTypeOptions: []
    }
  },
  created() {
    getDicts('sys_common_status').then(res => {
      let response = res.data;
      if (response.success) {
        this.statusOptions = response.data
      }
    })
    getDicts('data_db_type').then(res => {
      let response = res.data;
      if (response.success) {
        this.dbTypeOptions = response.data
      }
    })
  },
  methods: {
    showCard() {
      // this.$emit('showCard', this.showOptions)
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    /** 步骤条下一步 */
    handleNextStep() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.active++
        }
      })
    },
    /** 步骤条上一步 */
    handleLastStep() {
      this.active--
    },
    /** 检测数据库连通性 */
    handleCheckConnection() {
      this.$refs['form2'].validate(valid => {
        if (valid) {
          this.form.dbSchema = this.form2
          checkConnection(this.form).then(res => {
            let response = res.data;
            if (response.success) {
              this.$message.success(this.$t('datacenter.tips.connectionSuccess'))
            }
          })
        }
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form2'].validate(valid => {
        if (valid) {
          this.$loading();
          this.form.dbSchema = this.form2
          this.loadingOptions.loading = true
          this.loadingOptions.loadingText = this.$t('datacenter.tips.saving')
          this.loadingOptions.isDisabled = true
          addDataSource(this.form).then(res => {
            let response = res.data;
            if (response.success) {
              this.$message.success(this.$t('datacenter.tips.saveSuccess'))
              setTimeout(() => {
                // 2秒后跳转列表页
                // this.$emit('showCard', this.showOptions)
                this.$router.$avueRouter.closeTag();
                this.$router.back()
              }, 1000)
            } else {
              this.$message.error(this.$t('datacenter.tips.saveError'))
              this.loadingOptions.loading = false
              this.loadingOptions.loadingText = this.$t('cip.cmn.btn.saveBtn')
              this.loadingOptions.isDisabled = false
            }
            this.$loading().close();
          }).catch(() => {
            this.loadingOptions.loading = false
            this.loadingOptions.loadingText = this.$t('cip.cmn.btn.saveBtn')
            this.loadingOptions.isDisabled = false
          })
        }
      })
    }
  }
}
</script>
